import { maskitoPhoneOptionsGenerator } from '@maskito/phone';
import metadata from 'libphonenumber-js/metadata.min.json';

const DIGIT = /\d/;
const NO_MASK = /.*/;
export const regNumMask = [
  DIGIT,
  DIGIT,
  DIGIT,
  DIGIT,
  DIGIT,
  '/',
  DIGIT,
  DIGIT,
  DIGIT,
  DIGIT,
  DIGIT,
  DIGIT,
  '/',
  DIGIT,
  DIGIT,
  DIGIT,
  DIGIT,
  DIGIT,
  DIGIT,
  DIGIT,
];

export const correctionRegNumMask = [
  DIGIT,
  DIGIT,
  DIGIT,
  DIGIT,
  DIGIT,
  '/',
  DIGIT,
  DIGIT,
  DIGIT,
  DIGIT,
  DIGIT,
  DIGIT,
  '/',
  'С',
  DIGIT,
  DIGIT,
  DIGIT,
  DIGIT,
  DIGIT,
  DIGIT,
];

export const unpMask = [
  DIGIT,
  DIGIT,
  DIGIT,
  DIGIT,
  DIGIT,
  DIGIT,
  DIGIT,
  DIGIT,
  DIGIT,
];

export const postalIndexMask = [DIGIT, DIGIT, DIGIT, DIGIT, DIGIT, DIGIT];

export const belarusPhoneMaskWithoutSpaces = [
  '+',
  '3',
  '7',
  '5',
  DIGIT,
  DIGIT,
  DIGIT,
  DIGIT,
  DIGIT,
  DIGIT,
  DIGIT,
  DIGIT,
  DIGIT,
];

export const exportLicenseMask = [
  DIGIT,
  DIGIT,
  DIGIT,
  DIGIT,
  DIGIT,
  DIGIT,
  '/',
  DIGIT,
  DIGIT,
  DIGIT,
  DIGIT,
  DIGIT,
  DIGIT,
  '/',
  DIGIT,
  DIGIT,
  DIGIT,
  DIGIT,
  DIGIT,
];
export const noMask = new RegExp('.*');
export const onlyDigitsMask = new RegExp('^[0-9]+$');

export const phoneMask = maskitoPhoneOptionsGenerator({
  metadata,
  strict: false,
  countryIsoCode: 'BY',
  separator: '',
});
export const specialCharsRegex = /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/g;
